.obj h2 {
  color: #053871;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin-top: 2rem;
}
.obj .obj-photo {
  overflow: hidden;
  max-height: 16rem;
  border-bottom: 1px solid #ccc;
}
.obj .obj-gallery {
  height: 40vh;
}
.obj .obj-description {
  padding: 1rem;
  background: #fff;
}
.obj .price-reg {
  font-size: 1.4rem;
}
.obj .price-reg:after {
  font-size: 1rem;
}
.obj .price-sq {
  font-size: 0.9rem;
  color: #999;
  display: block;
  margin-bottom: 1rem;
}
.obj .title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.obj .address {
  font-size: 1rem;
}
.obj .newflat {
  color: #249ae0;
}
.obj .description {
  margin-top: 1rem;
  font-size: 1rem;
}
.obj-list .notice {
  padding: 1rem;
  font-size: 0.8rem;
}
.obj .contacts {
  margin-bottom: 2rem;
}
.obj .contacts-name {
  font-size: 1.2rem;
}
.obj .contacts .dt {
  color: #999;
  font-size: 0.9rem;
}
.obj .carousel-item {
  height: 40vh;
}
.obj .carousel-item img {
  width: 100%;
}
.obj .etc {
  font-size: 0.8rem;
  color: #999;
}
.obj .favorite {
  float: right;
  font-size: 2.5rem;
  margin: 0 1rem;
  color: #ccc;
}
.obj .favorite-active {
  color: #c00;
}
.widget-swipe {
  height: auto;
  overflow: scroll;
  overflow-y: hidden;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.widget-swipe::-webkit-scrollbar {
  width: 0;
}
.widget-swipe ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.widget-swipe li {
  display: flex;
  background: #fff;
  font-size: 0.7em;
  padding: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
  border-radius: 0.3rem;
  margin: 0 0.2rem;
}
.widget-swipe a {
  color: #444;
  text-decoration: none;
}
.widget-swipe a:visited {
  opacity: 0.5;
}
.widget-swipe figure {
  width: 10rem;
  height: 6rem;
  margin-bottom: 0.5rem;
}
.widget-swipe .title {
  font-size: 0.8rem;
  line-height: 1rem;
  margin: 0.2rem 0 0.5rem;
}
.widget-swipe .price-reg {
  font-size: 0.7rem;
  font-weight: bold;
}
.widget-swipe .price-reg:after {
  font-size: 0.7rem;
}
.obj-result {
  text-align: center;
  padding: 1rem 0;
}
.obj-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.obj-list a {
  color: #444;
  text-decoration: none;
}
.obj-list li {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}
.obj-list .obj-photo {
  overflow: hidden;
  max-height: 12rem;
}
.obj-list .obj-description {
  padding: 1rem;
  border-top: 1px solid rgba(5, 56, 113, 0.1);
}
.obj-list .price-reg {
  font-size: 1.4rem;
}
.obj-list .price-reg:after {
  font-size: 1rem;
}
.obj-list .price-sq {
  font-size: 0.8rem;
  display: block;
  color: #999;
  margin-top: -0.2rem;
}
.obj-list .favorite {
  float: right;
  font-size: 2.5rem;
  margin: 0 1rem;
  color: #ccc;
}
.obj-list .favorite-active {
  color: #c00;
}
.obj-list .title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.obj-list .address {
  font-size: 0.8rem;
}
.obj-list .newflat {
  color: #249ae0;
}
.obj-result {
  text-align: center;
  padding: 1rem;
}
.obj-actions {
  width: 100%;
  text-align: center;
  bottom: 1rem;
  z-index: 1000;
}
.obj-list figure {
  height: 40vh;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}
.obj-list .obj-item[data-services~="top"] {
  background: #fef3d3;
}
.obj-list .obj-item-notactive {
  opacity: 0.4;
}
.contacts-phone {
  text-align: center;
  font-size: 2rem;
}
.search-form {
  background: #fff;
  padding: 1rem 1rem 2rem;
}
.search-form .obj-actions {
  margin-top: 2rem;
}
.search-form .form-switches {
  margin-top: 1rem;
}
.disclaimer {
  margin-top: 1rem;
  color: #999;
  font-size: 0.8rem;
}
.metro {
  font-size: 0.7rem;
  margin-top: 0.5rem;
}
.metro-icon {
  display: block;
}
.metro-icon:before {
  content: 'М';
  background: #999;
  border-radius: 1em;
  margin-right: 0.2rem;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
  line-height: 0.7rem;
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
}
.userinfo .name {
  font-size: 1.2rem;
}
.userinfo .dt {
  color: #999;
  font-size: 0.9rem;
}
