.city-general {
  margin-bottom: 2rem;
}

.city-select {
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  background: #fff;
  border-radius: .3rem;
}

.city-title {
  font-size: 1.1rem;
  color: #249ae0;
}
