.news-info {
  color: #777;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.news-links ul {
  list-style: none;
  padding: 0;
}

.news article {
  padding: 1rem;
}

.news .carousel-item {
  height: 40vh;
  text-align: center;
}

.news .carousel-item img {
  height: 40vh;
}

.news-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4em 0;
}

.news-list a {
  text-decoration: none;
  color: #444;
}

.news-list li {
  font-size: .9rem;
  margin-bottom: 1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}

.news-list .content {
  padding: 1rem;
}

.news-list .news-title {
  font-size: 1.2rem;
}

.news-list .news-info {
  color: #777;
  font-size: 0.8rem;
  margin: 1rem 0 0;
}

.news-list figure, .news figure {
  height: 40vh;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}

.news-list figure {
  height: 30vh;
}
