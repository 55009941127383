header .navbar {
  transition: top 0.3s;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  padding: 0.2rem 1rem;
}
header .navbar-dark {
  background: #073C7C;
}
header .navbar-dark .opened .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 224 224'%3E%3Cg fill='rgba(255,255,255,.5)'%3E%3Cpath d='M43.93229,30.73438l-13.19792,13.19792l68.06771,68.06771l-68.06771,68.06771l13.19792,13.19791l68.06771,-68.06771l68.06771,68.06771l13.19791,-13.19791l-68.06771,-68.06771l68.06771,-68.06771l-13.19791,-13.19792l-68.06771,68.06771z'/%3E%3C/g%3E%3C/svg%3E%0A");
}
header .navbar-dark .fav-link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.5em;
  line-height: 0.5em;
}
header .navbar-light {
  background: #fff;
  color: #000;
  height: 3rem;
}
header .navbar-light .nav-link {
  font-size: 1.5em;
  line-height: 0.5em;
  padding: 0;
}
header .navbar-brand .logo {
  margin-top: -1rem;
}
header .navbar-brand .city-name {
  color: #fff;
  font-size: 0.7rem;
  font-weight: bold;
  position: absolute;
  margin: -0.8rem 0 0 1.6rem;
}
header .navbar-toggler {
  border: none;
  outline: none;
}
header .navbar-collapse {
  margin: 0 -1rem;
  padding: 0 1rem;
  background: #073C7C;
}
header .navbar-nav {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  min-height: 100vh;
}
header .navbar-nav .nav-item .nav-link {
  padding: 0.4rem 0;
}
header .navbar-nav .nav-item .nav-link i {
  display: inline-block;
  padding-right: 0.5rem;
  font-size: 1.2rem;
}
header .navbar-nav .system {
  padding-top: 2rem;
  color: rgba(255, 255, 255, 0.3);
}
