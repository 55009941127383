.owner {
  background: #fff;
  min-height: 100%;
}

.owner article {

}

.owner-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.owner-list a {
  color: inherit;
}

.owner-list .name {
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: .5rem;
}

.owner-list .subname {
  font-size: .8rem;
}

.owner-list .dt {
  font-size: .7rem;
  color: #999;
}

.owner-list .counter {
  font-size: .9rem;
  line-height: .9rem;
  text-align: right;
  color: #999;
}

.owner .contacts-call a {
  text-decoration: none;
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #007bff;
  border: .1rem solid #007bff;
  border-radius: .5rem;
}

.owner figure {
  height: 60vh;
  background-size: cover;
  background-position: top;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}
