body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}
html,
body,
root,
main {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
h1 {
  font-size: 1.5rem;
  margin: 1rem 0 1rem;
}
h1.text-left {
  margin-left: 1rem;
}
h2 {
  font-size: 1.3rem;
  margin: 1rem 0;
  text-align: center;
}
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}
a {
  text-decoration: none;
  color: inherit;
}
footer {
  padding: 1rem;
  background: #e6e6eb;
}
figure {
  height: 100%;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
.list-view {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-view li {
  padding: 0;
}
.content {
  padding: 1rem;
}
main {
  padding-top: 3rem;
}
article {
  background: #fff;
  padding: 3rem 1rem 1rem 1rem;
  min-height: 100%;
}
article h1 {
  margin-top: 0;
}
article.full-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.fullscreen {
  z-index: 9999;
  position: absolute;
}
.error-page button {
  margin-top: 3rem;
}
.center {
  margin: auto;
  text-align: center;
}
.list-group a {
  color: inherit;
}
.list-group-item .row,
a > .row {
  align-items: center;
  padding-right: 1.5rem;
}
.list-group-item .row:after,
a > .row:after {
  content: "❯";
  color: #ccc;
  font-size: 1rem;
  position: absolute;
  right: 1rem;
}
.list-group .counter {
  text-align: right;
}
.avatar {
  height: 4rem;
  width: 4rem;
  background-color: #eee;
}
.avatar-small {
  height: 2.7rem;
  width: 2.7rem;
  background-color: #eee;
}
.bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 10;
}
.bottom-padding {
  padding-bottom: 5rem !important;
}
.bottom-fixed button {
  display: block;
  margin: auto;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  color: #fff;
  border: none;
  border-radius: 1.5rem;
  background: #007bff;
  box-shadow: 0 0 1rem 0.2em rgba(0, 0, 0, 0.1);
}
.bottom-fixed button i {
  display: block;
  float: left;
  background: #fff;
  color: #007bff;
  font-size: 1.2rem;
  line-height: 1.7rem;
  height: 1.5em;
  width: 1.5em;
  margin: 0 0.7rem 0 -1rem;
  border-radius: 1.5rem;
}
.bottom-fixed .badge {
  text-align: center;
  border-radius: 1em;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.2em;
}
.button-big {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #66B33E;
  border-radius: 1em;
  font-size: 3em;
  line-height: 3rem;
  width: 5rem;
  height: 5rem;
  color: #fff;
  border: none;
  box-shadow: 0 0 1rem 0.2em rgba(0, 0, 0, 0.1);
}
.facet-list {
  padding: 1rem;
  overflow: scroll;
  overflow-y: hidden;
  height: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.facet-list::-webkit-scrollbar {
  width: 0;
}
.facet-list div {
  display: flex;
  justify-content: space-between;
}
.facet-list button {
  margin-left: 0;
  margin-right: 0.5rem;
  background: #fff;
  border-radius: 2em;
  border: none;
  padding: 0.5rem 2rem;
  white-space: nowrap;
  box-shadow: 0 0.2rem 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
}
