.tips-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.tips-list a {
  color: inherit;
}

.tips-list li {
  margin-bottom: .4rem;
  padding: 1rem;
  font-size: 1.1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}
