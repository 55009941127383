body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}
html,
body,
root,
main {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
h1 {
  font-size: 1.5rem;
  margin: 1rem 0 1rem;
}
h1.text-left {
  margin-left: 1rem;
}
h2 {
  font-size: 1.3rem;
  margin: 1rem 0;
  text-align: center;
}
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}
a {
  text-decoration: none;
  color: inherit;
}
footer {
  padding: 1rem;
  background: #e6e6eb;
}
figure {
  height: 100%;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
.list-view {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-view li {
  padding: 0;
}
.content {
  padding: 1rem;
}
main {
  padding-top: 3rem;
}
article {
  background: #fff;
  padding: 3rem 1rem 1rem 1rem;
  min-height: 100%;
}
article h1 {
  margin-top: 0;
}
article.full-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.fullscreen {
  z-index: 9999;
  position: absolute;
}
.error-page button {
  margin-top: 3rem;
}
.center {
  margin: auto;
  text-align: center;
}
.list-group a {
  color: inherit;
}
.list-group-item .row,
a > .row {
  align-items: center;
  padding-right: 1.5rem;
}
.list-group-item .row:after,
a > .row:after {
  content: "\276F";
  color: #ccc;
  font-size: 1rem;
  position: absolute;
  right: 1rem;
}
.list-group .counter {
  text-align: right;
}
.avatar {
  height: 4rem;
  width: 4rem;
  background-color: #eee;
}
.avatar-small {
  height: 2.7rem;
  width: 2.7rem;
  background-color: #eee;
}
.bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 10;
}
.bottom-padding {
  padding-bottom: 5rem !important;
}
.bottom-fixed button {
  display: block;
  margin: auto;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  color: #fff;
  border: none;
  border-radius: 1.5rem;
  background: #007bff;
  box-shadow: 0 0 1rem 0.2em rgba(0, 0, 0, 0.1);
}
.bottom-fixed button i {
  display: block;
  float: left;
  background: #fff;
  color: #007bff;
  font-size: 1.2rem;
  line-height: 1.7rem;
  height: 1.5em;
  width: 1.5em;
  margin: 0 0.7rem 0 -1rem;
  border-radius: 1.5rem;
}
.bottom-fixed .badge {
  text-align: center;
  border-radius: 1em;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.2em;
}
.button-big {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #66B33E;
  border-radius: 1em;
  font-size: 3em;
  line-height: 3rem;
  width: 5rem;
  height: 5rem;
  color: #fff;
  border: none;
  box-shadow: 0 0 1rem 0.2em rgba(0, 0, 0, 0.1);
}
.facet-list {
  padding: 1rem;
  overflow: scroll;
  overflow-y: hidden;
  height: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.facet-list::-webkit-scrollbar {
  width: 0;
}
.facet-list div {
  display: flex;
  justify-content: space-between;
}
.facet-list button {
  margin-left: 0;
  margin-right: 0.5rem;
  background: #fff;
  border-radius: 2em;
  border: none;
  padding: 0.5rem 2rem;
  white-space: nowrap;
  box-shadow: 0 0.2rem 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
}

.obj h2 {
  color: #053871;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin-top: 2rem;
}
.obj .obj-photo {
  overflow: hidden;
  max-height: 16rem;
  border-bottom: 1px solid #ccc;
}
.obj .obj-gallery {
  height: 40vh;
}
.obj .obj-description {
  padding: 1rem;
  background: #fff;
}
.obj .price-reg {
  font-size: 1.4rem;
}
.obj .price-reg:after {
  font-size: 1rem;
}
.obj .price-sq {
  font-size: 0.9rem;
  color: #999;
  display: block;
  margin-bottom: 1rem;
}
.obj .title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.obj .address {
  font-size: 1rem;
}
.obj .newflat {
  color: #249ae0;
}
.obj .description {
  margin-top: 1rem;
  font-size: 1rem;
}
.obj-list .notice {
  padding: 1rem;
  font-size: 0.8rem;
}
.obj .contacts {
  margin-bottom: 2rem;
}
.obj .contacts-name {
  font-size: 1.2rem;
}
.obj .contacts .dt {
  color: #999;
  font-size: 0.9rem;
}
.obj .carousel-item {
  height: 40vh;
}
.obj .carousel-item img {
  width: 100%;
}
.obj .etc {
  font-size: 0.8rem;
  color: #999;
}
.obj .favorite {
  float: right;
  font-size: 2.5rem;
  margin: 0 1rem;
  color: #ccc;
}
.obj .favorite-active {
  color: #c00;
}
.widget-swipe {
  height: auto;
  overflow: scroll;
  overflow-y: hidden;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.widget-swipe::-webkit-scrollbar {
  width: 0;
}
.widget-swipe ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.widget-swipe li {
  display: flex;
  background: #fff;
  font-size: 0.7em;
  padding: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
  border-radius: 0.3rem;
  margin: 0 0.2rem;
}
.widget-swipe a {
  color: #444;
  text-decoration: none;
}
.widget-swipe a:visited {
  opacity: 0.5;
}
.widget-swipe figure {
  width: 10rem;
  height: 6rem;
  margin-bottom: 0.5rem;
}
.widget-swipe .title {
  font-size: 0.8rem;
  line-height: 1rem;
  margin: 0.2rem 0 0.5rem;
}
.widget-swipe .price-reg {
  font-size: 0.7rem;
  font-weight: bold;
}
.widget-swipe .price-reg:after {
  font-size: 0.7rem;
}
.obj-result {
  text-align: center;
  padding: 1rem 0;
}
.obj-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.obj-list a {
  color: #444;
  text-decoration: none;
}
.obj-list li {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}
.obj-list .obj-photo {
  overflow: hidden;
  max-height: 12rem;
}
.obj-list .obj-description {
  padding: 1rem;
  border-top: 1px solid rgba(5, 56, 113, 0.1);
}
.obj-list .price-reg {
  font-size: 1.4rem;
}
.obj-list .price-reg:after {
  font-size: 1rem;
}
.obj-list .price-sq {
  font-size: 0.8rem;
  display: block;
  color: #999;
  margin-top: -0.2rem;
}
.obj-list .favorite {
  float: right;
  font-size: 2.5rem;
  margin: 0 1rem;
  color: #ccc;
}
.obj-list .favorite-active {
  color: #c00;
}
.obj-list .title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.obj-list .address {
  font-size: 0.8rem;
}
.obj-list .newflat {
  color: #249ae0;
}
.obj-result {
  text-align: center;
  padding: 1rem;
}
.obj-actions {
  width: 100%;
  text-align: center;
  bottom: 1rem;
  z-index: 1000;
}
.obj-list figure {
  height: 40vh;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}
.obj-list .obj-item[data-services~="top"] {
  background: #fef3d3;
}
.obj-list .obj-item-notactive {
  opacity: 0.4;
}
.contacts-phone {
  text-align: center;
  font-size: 2rem;
}
.search-form {
  background: #fff;
  padding: 1rem 1rem 2rem;
}
.search-form .obj-actions {
  margin-top: 2rem;
}
.search-form .form-switches {
  margin-top: 1rem;
}
.disclaimer {
  margin-top: 1rem;
  color: #999;
  font-size: 0.8rem;
}
.metro {
  font-size: 0.7rem;
  margin-top: 0.5rem;
}
.metro-icon {
  display: block;
}
.metro-icon:before {
  content: '\41C';
  background: #999;
  border-radius: 1em;
  margin-right: 0.2rem;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
  line-height: 0.7rem;
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
}
.userinfo .name {
  font-size: 1.2rem;
}
.userinfo .dt {
  color: #999;
  font-size: 0.9rem;
}

.gallery figure {
  background-color: #f5f5f5;
  margin: 0;
}

.gallery {
  height: 40vh;
}

.loader-inline {
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 0;
}

.loader-fullscreen {
  position: fixed;
  top: 50vh;
  z-index: 0;
  width: 100%;
}

.spinner {
  margin: auto;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

.city-general {
  margin-bottom: 2rem;
}

.city-select {
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  background: #fff;
  border-radius: .3rem;
}

.city-title {
  font-size: 1.1rem;
  color: #249ae0;
}

.owner-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.owner-list a {
  color: inherit;
}

.owner-list .name {
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: .5rem;
}

.owner-list .subname {
  font-size: .8rem;
}

.owner-list .dt {
  font-size: .7rem;
  color: #999;
}

.owner-list .counter {
  font-size: .9rem;
  line-height: .9rem;
  text-align: right;
  color: #999;
}

.owner .contacts-call a {
  text-decoration: none;
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #007bff;
  border: .1rem solid #007bff;
  border-radius: .5rem;
}

.owner figure {
  height: 40vh;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}

.news-info {
  color: #777;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.news-links ul {
  list-style: none;
  padding: 0;
}

.news article {
  padding: 1rem;
}

.news .carousel-item {
  height: 40vh;
  text-align: center;
}

.news .carousel-item img {
  height: 40vh;
}

.news-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4em 0;
}

.news-list a {
  text-decoration: none;
  color: #444;
}

.news-list li {
  font-size: .9rem;
  margin-bottom: 1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}

.news-list .content {
  padding: 1rem;
}

.news-list .news-title {
  font-size: 1.2rem;
}

.news-list .news-info {
  color: #777;
  font-size: 0.8rem;
  margin: 1rem 0 0;
}

.news-list figure, .news figure {
  height: 40vh;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}

.news-list figure {
  height: 30vh;
}

.tips-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.tips-list a {
  color: inherit;
}

.tips-list li {
  margin-bottom: .4rem;
  padding: 1rem;
  font-size: 1.1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}

.tips-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.tips-list a {
  color: inherit;
}

.tips-list li {
  margin-bottom: .4rem;
  padding: 1rem;
  font-size: 1.1rem;
  background: #fff;
  color: #3d3d3d;
  box-shadow: 0 0.2rem 0.4rem #bdbdbd;
}

.owner {
  background: #fff;
  min-height: 100%;
}

.owner article {

}

.owner-list {
  list-style: none;
  margin: 0;
  padding: 0 0 4rem 0;
}

.owner-list a {
  color: inherit;
}

.owner-list .name {
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: .5rem;
}

.owner-list .subname {
  font-size: .8rem;
}

.owner-list .dt {
  font-size: .7rem;
  color: #999;
}

.owner-list .counter {
  font-size: .9rem;
  line-height: .9rem;
  text-align: right;
  color: #999;
}

.owner .contacts-call a {
  text-decoration: none;
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #007bff;
  border: .1rem solid #007bff;
  border-radius: .5rem;
}

.owner figure {
  height: 60vh;
  background-size: cover;
  background-position: top;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin: 0;
}

header .navbar {
  transition: top 0.3s;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  padding: 0.2rem 1rem;
}
header .navbar-dark {
  background: #073C7C;
}
header .navbar-dark .opened .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 224 224'%3E%3Cg fill='rgba(255,255,255,.5)'%3E%3Cpath d='M43.93229,30.73438l-13.19792,13.19792l68.06771,68.06771l-68.06771,68.06771l13.19792,13.19791l68.06771,-68.06771l68.06771,68.06771l13.19791,-13.19791l-68.06771,-68.06771l68.06771,-68.06771l-13.19791,-13.19792l-68.06771,68.06771z'/%3E%3C/g%3E%3C/svg%3E%0A");
}
header .navbar-dark .fav-link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.5em;
  line-height: 0.5em;
}
header .navbar-light {
  background: #fff;
  color: #000;
  height: 3rem;
}
header .navbar-light .nav-link {
  font-size: 1.5em;
  line-height: 0.5em;
  padding: 0;
}
header .navbar-brand .logo {
  margin-top: -1rem;
}
header .navbar-brand .city-name {
  color: #fff;
  font-size: 0.7rem;
  font-weight: bold;
  position: absolute;
  margin: -0.8rem 0 0 1.6rem;
}
header .navbar-toggler {
  border: none;
  outline: none;
}
header .navbar-collapse {
  margin: 0 -1rem;
  padding: 0 1rem;
  background: #073C7C;
}
header .navbar-nav {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  min-height: 100vh;
}
header .navbar-nav .nav-item .nav-link {
  padding: 0.4rem 0;
}
header .navbar-nav .nav-item .nav-link i {
  display: inline-block;
  padding-right: 0.5rem;
  font-size: 1.2rem;
}
header .navbar-nav .system {
  padding-top: 2rem;
  color: rgba(255, 255, 255, 0.3);
}

